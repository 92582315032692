import { Tabs } from "antd";
import Select from "react-select";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";

import {
  EyeOutlined,
  CheckOutlined,
  CheckCircleOutlined,
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Card, Flex } from "antd";

import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import { useDispatch, useSelector } from "react-redux";
import { selectSelectedEvent } from "components/MapDashboard/Events/eventSlice";
import {
  closeMenu,
  selectMenuOpen,
  toggleMenu,
} from "components/MapDashboard/mapDashboardSlice";
import {
  useGetDistrictsByFiltersQuery,
  useGetStatesListQuery,
} from "services/api/masterDataApiSlice";

import EventList from "components/MapDashboard/Events/EventList";
import EventInfo from "components/MapDashboard/Events/EventInfo";
import MapDashboard from "components/MapDashboard";
import Map from "components/Map";
import EarthObs from "../assets/images/productImages/earth_observatio_ insights.jpg";
import climateSmart from "../assets/images/productImages/climate_smart-events.jpg";
import impact from "../assets/images/productImages/impact.jpg";
import resillience from "../assets/images/productImages/resillience_sustainability.jpg";
import riskIntelligence from "../assets/images/productImages/risk_intelligence.jpg";
const { Meta } = Card;
const Dashboard = () => {
  // const { data: statesApiResponse } = useGetStatesListQuery();
  // const [stateOptions, setStateOptions] = useState([]);

  // const [districtApiFilters, setDistrictApiFilters] = useState({});
  // const { data: districtsApiResponse } =
  //   useGetDistrictsByFiltersQuery(districtApiFilters);
  // const [districtOptions, setDistrictOptions] = useState([]);

  // useEffect(() => {
  //   let data = statesApiResponse?.data.map((item) => ({
  //     label: item.state_name,
  //     value: item.state_code,
  //   }));
  //   if (!data) return;

  //   setStateOptions(data);
  //   setDistrictOptions([]);
  // }, [statesApiResponse]);

  // useEffect(() => {
  //   let data = districtsApiResponse?.data.map((item) => ({
  //     label: item.district_name,
  //     value: item.district_code,
  //   }));
  //   if (!data) data = [];

  //   setDistrictOptions(data);
  // }, [districtsApiResponse]);

  // const handleStateSelection = (value) => {
  //   setDistrictApiFilters({ state_id: value.value });
  // };

  // const [events, setEvents] = useState([]);

  // useEffect(() => {
  //   const page = 1;
  //   const perPage = 20;
  //   const API_URL = `https://api.resourcewatch.org/v1/dataset?env=production&application=rw&language=en&includes=layer,metadata,vocabulary,widget&sort=-createdAt&status=saved&published=true&page[number]=${page}&page[size]=${perPage}`;
  //   fetch(API_URL).then((res) => {
  //     res.json().then((response) => {
  //       const eventsData = response.data.map((item) => {
  //         return {
  //           name: item.attributes.name,
  //           date: item.attributes?.createdAt,
  //           thumbnailUrl: item.attributes?.layer[0]?.attributes.thumbnailUrl,
  //         };
  //       });
  //       setEvents(eventsData);
  //     });
  //   });
  // }, []);

  // const dispatch = useDispatch();
  // const menuOpen = useSelector(selectMenuOpen);
  // const selectedEvent = useSelector(selectSelectedEvent);

  // const handleOptionsSearch = (input, option) =>
  //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  // const TabItems = [
  //   {
  //     key: "1",
  //     label: "Events",
  //     children: <EventList className={"h-[550px]"} events={events} />,
  //   },
  // ];

  const ServicesItems = [
    // {
    //   id: "1",
    //   label: (
    //     <h1 className="card__title">
    //       <mark>Earth</mark> Observation Insights
    //     </h1>
    //   ),
    //   description: (
    //     <ul className="text-[#888] list-disc">
    //       <li>
    //         <CheckCircleOutlined className="text-green-600 mr-1" /> Disturbance
    //         Analytics – Biodiversity & Forest
    //       </li>
    //       <li>
    //         <CheckCircleOutlined className="text-green-600 mr-1" /> Resilience
    //         Analytics
    //       </li>
    //       <li>
    //         <CheckCircleOutlined className="text-green-600 mr-1" /> Land System
    //         Analytics
    //       </li>
    //       <li>
    //         <CheckCircleOutlined className="text-green-600 mr-1" /> Mitigation
    //         Analytics
    //       </li>
    //     </ul>
    //   ),
    //   link: "",
    //   image: EarthObs,
    // },
    {
      id: "2",
      label: (
        <h1 className="card__title">
          <mark>Climate</mark> Smart Analytics
        </h1>
      ),
      description: (
        <ul className="text-[#888] list-disc">
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Scores –
            Performance Metrics
          </li>
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Yield
            Ecosystem Analytics
          </li>
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Price
            Forecasting
          </li>
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Food
            Security – Supply & Demand Analytics
          </li>
        </ul>
      ),
      link: "/pixstack-journey",
      image: climateSmart,
    },
    {
      id: "3",
      label: (
        <h1 className="card__title">
          <mark>Risk</mark> Intelligence
        </h1>
      ),
      description: (
        <ul className="text-[#888] list-disc">
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Risk
            Management Analytics
          </li>
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Causal
            Analytics
          </li>
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Biological
          </li>
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Forecast &
            Prediction
          </li>
        </ul>
      ),
      link: "/pixstack-journey",
      image: riskIntelligence,
    },
    {
      id: "4",
      label: (
        <h1 className="card__title">
          <mark>Impact</mark> Analytics
        </h1>
      ),
      description: (
        <ul className="text-[#888] list-disc">
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Disturbance
            Analytics – Biodiversity & Forest
          </li>
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Resilience
            Analytics
          </li>
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Land System
            Analytics
          </li>
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Carbon
            Footprint, Sequestration & Carbon
          </li>
        </ul>
      ),
      link: "/pixstack-journey",
      image: impact,
    },
    {
      id: "5",
      label: (
        <h1 className="card__title">
          <mark>Resilience</mark> & Sustainability
        </h1>
      ),
      description: (
        <ul className="text-[#888] list-disc">
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Location
            Analytics
          </li>
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Demographic
            Analytics
          </li>
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" />{" "}
            Sustainability Analytics – Environment
          </li>
          <li>
            <CheckCircleOutlined className="text-green-600 mr-1" /> Analytics –
            Economy & Society
          </li>
        </ul>
      ),
      link: "/pixstack-journey",
      image: resillience,
    },
  ];

  return (
    <>
      <div className="w-full relative justify-center items-center space-y-10 overflow-y-scroll ">
        <div className="container">
          <div className="my-2">
            <Card className="m-auto  w-[100%] flex justify-center items-center p-0 !align-middle transparent border-0">
              <CardContent className="w-100 flex flex-col justify-center items-center !p-0">
                <Typography
                  className="!font-bold"
                  color={"white"}
                  variant="h4"
                  component="div"
                >
                 <mark> PixStack </mark>
                </Typography>
                <p className="text-gray-600 tracking-wider">A Causal & Actionable AI Platform</p>
              </CardContent>
            </Card>

            <Flex wrap="wrap" gap="large" className="ant-flex-justify-center">
              {ServicesItems && ServicesItems.map((item, index) => (
                <div key={index}>
                  {/* <Card
                  style={{ width: 350 }}
                  className=""
                  cover={<img
                    alt="example"
                    src={item.image}
                    style={{ height: "220px" }} />}
                  actions={[
                    // <SettingOutlined key="setting" />,
                    <a href="/data-pipeline">
                      {" "}
                      <EyeOutlined key="edit" className="f-16 " />{" "}
                    </a>,
                  ]}
                >
                  <Meta title={item.label} description={item.description} />
                </Card> */}
                  <article className="card cardEvent" key={index} >
                    {item.label}
                    {/* <p className="card__description">
                      As your business grows, so does your codebase. We will
                      audit your codebase and provide you with a detailed report
                      on how to improve it.
                    </p> */}
                    <div className="media-object text-white">
                      {item.description}
                    </div>
                    <div className="media-object__thumbnail overflow-hidden">
                      {" "}
                      <img
                        alt="example"
                        src={item.image}
                        style={{ height: "200px" }}
                      />
                    </div>
                    <div className="card__actions">
                      <a href={item.link} className="card__button  hover:scale-110  duration-300">
                        Explore Now
                      </a>
                    </div>
                  </article>
                </div>
              ))}
            </Flex>
          </div>
        </div>
      </div>
    </>
  );

  // return (
  //   <Box className="w-full h-full">
  //     <MapDashboard>
  //       <Box className="h-full">
  //         <EventInfo />
  //       </Box>
  //       {/* <Card elevation={2} className="h-full flex flex-col gap-2 p-4 mx-1 !bg-gray-200 border-b border-black">
  //         <Tabs
  //           items={TabItems}
  //           defaultActiveKey="1"
  //           className="!bg-gray-200"
  //           tabBarStyle={{ borderBottom: "1px solid lightgray" }}
  //         />
  //       </Card> */}
  //     </MapDashboard>
  //   </Box>
  // );
  // return (
  //   <div className="relative w-full h-full">
  //     <div
  //       className={`absolute w-[35%] h-full z-[600] duration-500 ease-in-out ${
  //         menuOpen ? "left-0" : "-left-[50%]"
  //       }`}
  //     >
  //       <section
  //         className={`absolute w-full bg-white h-full ${
  //           selectedEvent ? "left-0" : "-left-[100%]"
  //         } duration-700 ease-in-out`}
  //       >
  //         <EventInfo />
  //       </section>

  //       <section
  //         className={`absolute bg-gray-900 w-[80%] h-full ${
  //           !selectedEvent ? "left-0" : "-left-[100%]"
  //         } duration-700 ease-in-out`}
  //       >
  //         <header className={`relative h-[15%] bg-contain`}>
  //           <div className="bg-gray-900 w-full h-full flex flex-col z-0 p-4">
  //             <div className="flex gap-4">
  //               <Select
  //                 className="bg-gray-50 rounded"
  //                 // value={selectedState}
  //                 onChange={handleStateSelection}
  //                 options={stateOptions}
  //                 placeholder="State"
  //                 isSearchable={true}
  //                 styles={{
  //                   control: (provided) => ({
  //                     ...provided,
  //                     width: 190,
  //                     height: 40,
  //                   }),
  //                 }}
  //               />
  //               <Select
  //                 className="bg-gray-50 !text-sm rounded"
  //                 // value={selectedDistrict}
  //                 // onChange={handleDistrictSelection}
  //                 options={districtOptions}
  //                 placeholder="District"
  //                 isSearchable={true}
  //                 styles={{
  //                   control: (provided) => ({
  //                     ...provided,
  //                     width: 190,
  //                     height: 40,
  //                   }),
  //                 }}
  //               />
  //             </div>
  //           </div>
  //           <div className="absolute top-0 -right-14 p-4">
  //             <IconButton
  //               centerRipple={false}
  //               onClick={() => dispatch(closeMenu())}
  //               className="!text-black h-fit !rounded !bg-base-950"
  //             >
  //               <CloseRoundedIcon
  //                 fontSize="small"
  //                 className="text-orange-400"
  //               />
  //             </IconButton>
  //           </div>
  //         </header>
  //         <Card
  //           elevation={2}
  //           className="h-[calc(100%-15%)] flex flex-col gap-2 p-4 mx-1 !bg-gray-200 border-b border-black"
  //         >
  //           <Tabs
  //             items={TabItems}
  //             defaultActiveKey="1"
  //             className="bg-tra !bg-gray-200"
  //             tabBarStyle={{ borderBottom: "1px solid lightgray" }}
  //           />
  //         </Card>
  //       </section>
  //     </div>

  //     <div
  //       className={`absolute top-0 bg-gray-500 h-full z-[600] p-2 duration-500 ease-in-out ${
  //         menuOpen ? "-left-[30%]" : "left-0"
  //       }`}
  //     >
  //       <IconButton
  //         centerRipple={false}
  //         onClick={() => dispatch(toggleMenu())}
  //         className="!text-black h-fit !rounded !bg-base-950 w-fit "
  //       >
  //         {menuOpen ? (
  //           <ArrowBackRoundedIcon
  //             fontSize="small"
  //             className="text-orange-400"
  //           />
  //         ) : (
  //           <MenuIcon fontSize="small" className="text-orange-400" />
  //         )}
  //       </IconButton>
  //     </div>

  //     <Map />
  //   </div>
  // );
};

export default Dashboard;
